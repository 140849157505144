const regExp = /[^A-Za-z0-9ㄱ-ㅎㅏ-ㅣ가-힣ぁ-ゔァ-ヴー々〆〤\u4E00-\u9FFF\s]/g
const removingLineBreaksRegExp = /(\r\n|\n|\r)/gm

function getReplacedTitle(title) {
  const removedLineBreaks = title.replace(removingLineBreaksRegExp, '-')
  const finalTitle = removedLineBreaks.replace(regExp, '-')
  return finalTitle
}

function getATag() {
  let a = document.getElementById('download')
  if (!a) a = document.createElement('a')
  return a
}

function downloadByUrl(url, title = 'download', type = 'mp3') {
  const a = getATag()
  a.href = url
  a.download = `${getReplacedTitle(title)}.${type}`
  a.click()
}

async function downloadFile(url) {
  try {
    const fileName = getFileName(url)
    const response = await fetch(url)
    const blob = await response.blob()
    const blobUrl = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = blobUrl
    a.download = fileName
    a.click()
    URL.revokeObjectURL(blobUrl)
  } catch (error) {
    throw new Error(error)
  }
}

function getFileName(url) {
  return url.substring(url.lastIndexOf('/') + 1)
}

export {getReplacedTitle, downloadByUrl, downloadFile}

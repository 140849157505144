import {Editor, Doc, Paragraph, Text, isDisabledSpeedSetting, isDisabledStyleSetting} from 'tiptap'
import {History, Placeholder} from 'tiptap-extensions'
import Query from '@/editor/Query'
import Separator from '@/editor/Separator'
import Focus from '@/editor/Focus'
import {mlBaseSplitTexts, ruleBaseSplit} from '@/utils/text-splitter'
import backend from '@/backend/backend-api.js'
import TypecastEditorDownloader from './download'
import {doPaste, EditorView} from 'prosemirror-view'
import {i18n} from '@/i18n'
import config from '@/config/config'
import * as Sentry from '@sentry/vue'
import debounce from 'lodash-es/debounce'
import {EditorState, TextSelection} from 'prosemirror-state'
import {tiptapContentValue} from '@/store/modules/queryCache'
import {reMappedKeymap, splitBlockNotNewLine, splitBlock} from 'prosemirror-commands'
import {getOffsetTime} from '@/utils/time'
import {useModal} from '@/composables/useModal'
import SilencePopup from '@/components/editor/modal/SilencePopup.vue'
import {ProjectFactory} from 'light'
import * as API from '@/backend/video-editor-api'
import CONSTANTS from '@/config/constants'
import {useVideoDomStore} from 'store/editor'
import {onPiniaMounted} from 'store/plugins/OnPiniaMountedPlugin'
import {nanoid} from 'nanoid'
import {updateSlide} from 'video-dom'
import {isAnonymousPage} from '@/router'
import {
  getAvailableStyleLabelVersionList,
  getCurrentActorStyleLabel,
  getLastActorStyleLabel,
  getLastActorVersion,
} from '../actor/actor-version-helpers'

/**
 * @module typecast/$editor
 */

/**
 * @class TypecastEditor
 */
export default class TypecastEditor {
  static _typecastEditorInstance = null

  _store
  _storeKey
  _downloadInstance

  /**
   * @type {Editor}
   */
  _editor
  _context

  _currentProjectId = null
  _currentProjectTitle = null

  _onFullChangeCallback = null
  _onFocusCabllack = null
  _onBlurCallback = null
  _splitter = null

  _autoSaveUpdateTimer = null

  _isAutoSave = true

  _isVideoPage = false
  _editorUpdateDebounce

  _previousSilencePopupQueryId = ''

  get _isProjectLoading() {
    return this._store.state.typecast.editor.isProjectLoading
  }
  set _isProjectLoading(value) {
    this._store.commit(this.getEditorStoreItem('setIsProjectLoading'), value)
  }

  _isProjectSaving = false

  /**
   * @type {ReturnType<typeof useVideoDomStore>}
   */
  videoDomStore = undefined

  /**
   * @constructor
   * @param {Object} context Vue 객체
   * @param {Object} store store 객체
   */
  constructor(context, store) {
    this._context = context
    this._store = store
    // this._id = 1
    this._storeKey = {
      queryCache: `typecast/queryCache`,
      editor: `typecast/editor`,
      actor: `typecast/actor`,
      videoEditor: `typecast/videoEditor`,
    }

    // Reference: https://github.com/ueberdosis/tiptap/issues/1451
    EditorView.prototype.updateState = function updateState(state) {
      if (!this.docView) return // This prevents the matchesNode error on hot reloads
      this.updateStateInner(state, this.state.plugins != state.plugins)
    }

    onPiniaMounted('videoDom', () => (this.videoDomStore = useVideoDomStore()))
  }

  /**
   * @returns {TypecastEditor}
   */
  static getInstance(context, store) {
    if (!this._typecastEditorInstance) {
      this._typecastEditorInstance = new TypecastEditor(context, store)
    }
    return this._typecastEditorInstance
  }

  setEditorWatch() {
    if (this.savedProjectPayloadUnwatch) return
    this.savedProjectPayloadUnwatch = this._store.watch(
      () => this._store.getters['typecast/editor/isEditorModified'],
      val => {
        if (val && this._isAutoSave && !this._isProjectLoading) {
          this.setAutoSaveTrigger()
        }
      },
    )
  }

  clearEditorWatch() {
    if (this.savedProjectPayloadUnwatch) {
      this.savedProjectPayloadUnwatch()
      this.savedProjectPayloadUnwatch = null
    }
  }

  /**
   * @function createEditor
   * @param {Object} editorParams
   * @param {Object} editorParams.actor 초기에 적용 될 액터
   * @returns {Editor} Editor 객체
   */
  createEditor({actor, splitter, isAutoSave, isVideoPage, tiptap}) {
    this._editorUpdateDebounce = debounce(this.onEditorUpdate.bind(this), 300)
    if (splitter) {
      this._splitter = splitter
    }

    if (this._editor) {
      return this._editor
    }
    this._isVideoPage = isVideoPage
    this._isAutoSave = isAutoSave

    // remap splitBlock / splitBlockNotNewLine to Enter key event
    reMappedKeymap('Enter', isVideoPage ? splitBlockNotNewLine : splitBlock)

    this._editor = new Editor({
      extensions: [
        new Doc(),
        new Paragraph({
          actor: actor.actor_id,
          hasCandidateActor: this.hasCandidateActor.bind(this),
          getDefaultActor: this.getDefaultActor.bind(this),
        }),
        new Text(),
        new History(),
        new Query({
          handleParseDom: this.getActorAttribute.bind(this),
        }),
        new Separator({
          handleClickEvent: () => {
            // saparator clicked
          },
          handleClickOn: (view, pos, node, nodePos, event) => {
            const separatorDOM = event.target

            const {openModal, closeModal, modalList} = useModal()
            const nodeBeforeSeparator = view.state.doc.resolve(nodePos).nodeBefore

            const isTextNode = node => node?.type.name === 'text'
            const hasText = node => node?.text?.trim().length > 0

            if (isTextNode(nodeBeforeSeparator) && hasText(nodeBeforeSeparator)) {
              view.dom.addEventListener(
                'pointerdown',
                () => {
                  view.dom.removeAttribute('inputmode')
                },
                {once: true},
              )

              view.dom.setAttribute('inputmode', 'none')
              setTimeout(() => {
                view.focus()
              }, 0)
              const isSilencePopupOpened = modalList.value.find(modal => modal.component.name === SilencePopup.name)
              if (isSilencePopupOpened) {
                closeModal(SilencePopup.name)
              }

              if (this._previousSilencePopupQueryId === nodeBeforeSeparator.marks[0].attrs.id) {
                this._previousSilencePopupQueryId = ''
                return
              }

              // using setTimeout to prevent the popup from closing immediately
              setTimeout(() => {
                openModal(SilencePopup, {
                  view,
                  textNode: nodeBeforeSeparator,
                  separatorDOM,
                  separatorNodePos: nodePos,
                })

                this._previousSilencePopupQueryId = nodeBeforeSeparator.marks[0].attrs.id
              })
            }
          },
        }),
        new Focus({
          nested: true,
        }),
        new Placeholder({
          emptyEditorClass: 'is-editor-empty',
          emptyNodeClass: 'is-empty',
          emptyNodeText: i18n.t('place_holder'),
          showOnlyWhenEditable: true,
          showOnlyCurrent: true,
        }),
      ],
      autoFocus: true,
      onTransaction: ({transaction}) => {
        if (!transaction.docChanged) {
          return
        }

        this.updateParagraphIdList()
      },
      onUpdate: ({getJSON}) => {
        this._editorUpdateDebounce(getJSON)
      },
      onFocus: () => {
        if (this._isFunction(this._onFocusCabllack)) {
          this._onFocusCabllack()
        }
      },
      onBlur: () => {
        if (this._isFunction(this._onBlurCallback)) {
          this._onBlurCallback()
        }
      },
      onTextSplitter: this._onTextSplitter.bind(this),
      // tell ProseMirror to ignore drop event
      editorProps: {
        handleDOMEvents: {
          drop: (view, e) => {
            e.preventDefault()
          },
        },
      },
      // hide the drop position indicator
      dropCursor: {width: 0, color: 'transparent'},
      useBuiltInExtensions: false,
    })

    if (!tiptap) {
      tiptap = {
        type: 'doc',
        content: [
          {
            type: 'paragraph',
            attrs: {
              id: nanoid(),
              actor: actor.actor_id,
              rest: 0,
            },
          },
        ],
      }
    }
    this.setEditorViewWithLoadedContent(tiptap)

    this.updateParagraphIdList()

    /*FIXME: this._editor.view.focus()
     * 이 부분이 정말 필요한지 다시 봐야 함
     * vuex > currentQuery 부분에 대한 초기값 세팅 부분으로 보면 됨
     * 가끔 해당 정보가 없을때가 있는데 첫 로딩시 반듯이 있어야 함, HMR 때문에 없는것 같기도 ...
     * 참고로 focus가 제대로 들어가면 query부분에 커서 생기고 깜빡거림
     */
    this._editor.view.focus()
    return this._editor
  }
  onEditorUpdate(json) {
    setTimeout(() => {
      if (typeof json === 'function') {
        json = json()
      }

      this.updateVideoDomStoreCharacterId(json)

      const queries = this._store.getters[this.getQueryCacheStoreItem('queries')]
      const playBufferItems = this._store.getters[this.getQueryCacheStoreItem('playBufferItems')]
      const oldQueriesKey = Object.keys(queries)

      this._store.commit(`${this._storeKey.editor}/setTiptapContent`, json)
      const newQueriesMap = tiptapContentValue(json, this._store.state.typecast.queryCache)
      this._store.commit(`${this.getQueryCacheStoreItem('setQueries')}`, newQueriesMap)
      const newQueriesKey = Object.keys(newQueriesMap)
      const setOldQueriesKey = new Set(oldQueriesKey)
      const setNewQueruesKey = new Set(newQueriesKey)
      let fullChange = true
      for (const key of setNewQueruesKey) {
        if (setOldQueriesKey.has(key)) {
          fullChange = false
        }
      }
      if (fullChange) {
        if (this._isFunction(this._onFullChangeCallback)) {
          this._onFullChangeCallback()
        }
      }
      // 문장을 삭제했을 경우
      if (oldQueriesKey.length > newQueriesKey.length && playBufferItems.length) {
        const bufferQueryIdList = playBufferItems.map(buffer => {
          return buffer.queryId
        })
        bufferQueryIdList.map(buffer => {
          if (!newQueriesMap[buffer]) {
            this._store.commit(`${this._storeKey.queryCache}/removePlayBufferItemByQueryId`, buffer)
          }
        })

        if (newQueriesKey.length !== 0) {
          this._store.commit(`${this._storeKey.queryCache}/setNextPlayBuffer`)
        }
      }
    }, 0)
  }
  updateParagraphIdList() {
    if (!this._editor) {
      return
    }

    let changed = false
    const paragraphIdList = this._store.getters[this.getEditorStoreItem('paragraphIdList')]
    const newParagraphIdList = []

    for (const paragraph of this._editor.state.doc.content.content) {
      const newId = paragraph.attrs.id
      if (!newId) {
        continue
      }

      const oldId = paragraphIdList[newParagraphIdList.length]
      if (newId !== oldId) {
        changed = true
      }

      newParagraphIdList.push(newId)
    }

    if (changed || paragraphIdList.length !== newParagraphIdList.length) {
      this._store.commit(`${this._storeKey.editor}/setParagraphIdList`, newParagraphIdList)
    }
  }
  updateVideoDomStoreCharacterId(tiptapJson) {
    const actorIdByParagraph = tiptapJson.content.map(paragraph => paragraph.attrs.actor)

    const {slideList} = this.videoDomStore
    actorIdByParagraph.forEach((nextActorId, index) => {
      const slide = slideList[index]
      if (!slide) {
        return
      }

      const isCharacterChanged = nextActorId !== slide.characterId
      if (!isCharacterChanged) {
        return
      }

      const newSlideProperties = {
        characterId: nextActorId,
        characterSkinType: 'A',
        bodyType: 'UPPER',
      }

      updateSlide(newSlideProperties, index)
    })
  }
  setBlurEvent(callback) {
    this._onBlurCallback = callback
  }
  setFullChangeEvent(callback) {
    this._onFullChangeCallback = callback
  }

  setFocusEvent(callback) {
    this._onFocusCabllack = callback
  }
  /**
   * @funciton getEditor
   * @returns {Editor} tiptap Editor 객체
   */
  getEditor() {
    return this._editor
  }

  _onTextSplitter(text) {
    const blocks = []
    const transaction = this._editor.state.tr
    const curSelection = transaction.curSelection
    let curNode = curSelection.$from.parent
    let lang = ''
    if (curNode.type.name === 'doc') {
      curNode = curNode.content.content[0]
    }
    if (curNode && curNode.type.name === 'paragraph') {
      const actorId = curNode.attrs.actor
      const candidateActor = this._store.getters[this.getActorStoreItem('candidateActor')]
      const focusedActor = candidateActor.find(actor => actor.actor_id === actorId)
      if (focusedActor) {
        lang = focusedActor.language
      }
    }
    if (this._splitter === 'ml') {
      this._store.commit(`${this._storeKey.queryCache}/setPasteStart`, true)
      const paragraphs = this._isVideoPage ? [text.trim()] : text.trim().split(/(?:\r\n?|\n)+/)
      return mlBaseSplitTexts(this._context.$typecast.$http, {paragraphs})
        .then(res => {
          if (res.result.length > 0 && res.result[0].length > 0) {
            if (this._context.$nsEvent) {
              this._context.$nsEvent.event({
                category: 'after_edit',
                action: 'sentence_paste',
                label: {
                  sentence_length: res.result[0].length,
                },
              })
            }
          }
          return res
        })
        .catch(error => {
          console.error(error)
          this._context.notify({
            group: 'main',
            type: 'error',
            title: 'Failed!',
            text: i18n.t('speak.split_text_error'),
          })
        })
        .finally(() => {
          this._store.commit(`${this._storeKey.queryCache}/setPasteStart`, false)
        })
    } else {
      const paragraphs = this._isVideoPage ? [text.trim()] : text.trim().split(/(?:\r\n?|\n)+/)
      paragraphs.forEach(block => {
        const items = ruleBaseSplit({
          s: block,
          ignoreDq: this._splitter === 'pref-ignore-double-quote' ? true : false,
          lang,
        })
        if (items.length) {
          if (this._context.$nsEvent) {
            this._context.$nsEvent.event({
              category: 'after_edit',
              action: 'sentence_paste',
              label: {sentence_length: items.length},
            })
          }
          blocks.push(items)
        }
      })

      return new Promise(resolve => {
        resolve(blocks)
      })
    }
  }
  getEditorStoreKey() {
    return this._storeKey.editor
  }
  getActorStoreKey() {
    return this._storeKey.actor
  }
  getQueryCacheStoreKey() {
    return this._storeKey.queryCache
  }
  getVideoEditorStoreKey() {
    return this._storeKey.videoEditor
  }
  getActorStoreItem(name) {
    return `${this.getActorStoreKey()}/${name}`
  }
  getEditorStoreItem(name) {
    return `${this.getEditorStoreKey()}/${name}`
  }
  getQueryCacheStoreItem(name) {
    return `${this.getQueryCacheStoreKey()}/${name}`
  }
  getVideoEditorStoreItem(name) {
    return `${this.getVideoEditorStoreKey()}/${name}`
  }
  setTextContents(text) {
    doPaste(this._editor.view, text, text, new Event('setText'))
  }
  _isFunction(param) {
    return param && typeof param === 'function'
  }

  destroyEditor() {
    if (this._editorUpdateDebounce) this._editorUpdateDebounce.cancel()
    this._editor.destroy()
    this._onBlurCallback = null
    this._onFullChangeCallback = null
    this._onFocusCabllack = null
    this._editor = null
  }

  /**
   * 다운로드 Progress Callback
   *
   * @callback onProgress
   * @param {int} percentageValue 진행도 progress
   */

  /**
   * 다운로드 완료 시 Callback
   *
   * @callback onDownloadComplete
   * @param {Object} returnValue
   * @param {String} returnValue.url 오디오 URL
   * @param {String} returnValue.blob 오디오 blob
   */

  /**
   * 다운로드 중 오류 발생 시 callbacks
   * @callback onFailure
   * @param {Error} error 에러
   */

  /**
   * @function downloadAudio
   * @param {Object} downloadCallbacks
   * @param {onProgress} downloadCallbacks.onProgress 프로그래스 callback
   * @param {onDownloadComplete} downloadCallbacks.onDownloadComplete 오디오 다운로드 완료 시 callback
   * @param {onFailure} downloadCallbacks.onFailure 오디오 다운로드 실패 시 callback
   */
  downloadAudio({downloadWay, spectificQuery, onProgress, onDownloadComplete, onFailure}) {
    this._store.commit(`${this._storeKey.queryCache}/removePlayBufferItemAll`)
    const downloadInstance = new TypecastEditorDownloader({
      context: this._context,
      store: this._store,
      downloadWay,
      spectificQuery,
    })
    downloadInstance.downloadAudio({onProgress, onDownloadComplete, onFailure})
    return downloadInstance
  }

  shareAudio({onProgress, onShareComplete, onFailure}) {
    const downloadInstance = new TypecastEditorDownloader({
      context: this._context,
      store: this._store,
    })
    downloadInstance.shareAudio({onProgress, onShareComplete, onFailure})
    return downloadInstance
  }

  trimProjectName(projectName) {
    if (projectName.length > CONSTANTS.PROJECT_NAME_MAX_LENGTH) {
      this._context.notify({
        group: 'main',
        type: 'success',
        title: i18n.t('editor_header.notify_project_name_length'),
      })
    }
    return projectName.slice(0, CONSTANTS.PROJECT_NAME_MAX_LENGTH - 1)
  }

  async saveProject({title, projectId}) {
    if (isAnonymousPage()) {
      throw new Error({
        message: 'anonymous_save_error',
      })
    }

    try {
      this._isProjectSaving = true

      const projectPlayload = this._store.getters[this.getEditorStoreItem('projectPayload')]
      projectPlayload.name = title && this.trimProjectName(title)
      this._store.commit(this.getEditorStoreItem('setSavedProjectPayloadString'), JSON.stringify(projectPlayload))
      this._store.commit(this.getEditorStoreItem('setProjectTitle'), projectPlayload.name)

      const res = projectId
        ? await backend.saveProject(this._context.$typecast.$http, projectPlayload, projectId)
        : await backend.createProject(this._context.$typecast.$http, projectPlayload)

      const projectDetail = res.result
      const projectUrl = projectDetail?.project_v2_url || projectDetail?.project_url

      if (!projectUrl) {
        const error = new Error({
          message: 'save_error: not found project url',
        })
        throw error
      }
      const _projectId = projectUrl.substring(projectUrl.lastIndexOf('/') + 1)
      this.setProjectBaseInfo({
        projectRevision: projectDetail.revision,
        projectLastModified: getOffsetTime(projectDetail.last_modified_date * 1000),
      })
      this._store.commit(this.getEditorStoreItem('setProjectId'), _projectId)
      return {projectDetail: projectDetail.project, projectUrl, projectId: _projectId}
    } catch (error) {
      Sentry.withScope(scope => {
        scope.setTag('API', 'SAVE_PROJECT')
        Sentry.captureException(error)
      })
      throw error
    } finally {
      this._isProjectSaving = false
    }
  }

  async setProjectData({projectId, projectDetail}) {
    let slideList = []
    if (projectDetail.media_type === 'video') {
      const show = await API.getShow(this._context.$typecast.$http, projectId)
      slideList = show.slideList
    }

    const actorList = this._store.getters[this.getActorStoreItem('candidateActor')]

    projectDetail = ProjectFactory.factory(
      projectDetail,
      slideList,
      this._store.getters[this.getActorStoreItem('actors')],
      actorList,
    )

    const validActorVersions = {}
    Object.entries(projectDetail.v10.style_label_version_list).forEach(([actorId, version]) => {
      const actor = actorList.find(actor => actor.actor_id === actorId)
      if (!actor) {
        return
      }

      const styleLabelList = getAvailableStyleLabelVersionList(actor)
      if (!styleLabelList) {
        return
      }

      const actorVersionList = styleLabelList.map(styleLabel => styleLabel.name)
      const isValidActorVersion = actorVersionList.includes(version)

      if (!isValidActorVersion) {
        const validActorVersion = getLastActorVersion(actor)
        if (!validActorVersion) {
          return
        }

        validActorVersions[actorId] = validActorVersion
      }
    })

    Object.entries(validActorVersions).forEach(([actorId, validVersion]) => {
      projectDetail.v10.style_label_version_list[actorId] = validVersion
    })

    this.setProjectBaseInfo({
      projectTitle: projectDetail.name,
      projectRevision: projectDetail.revision || {},
      projectShare: projectDetail.share || {},
      projectLastModified: getOffsetTime(projectDetail.last_modified_date * 1000),
      projectId,
      projectFolderId: projectDetail.folder?._id,
    })

    const audioProject = projectDetail[`v${projectDetail.version}`]
    await this.setProjectDetail({
      document: audioProject.tiptap,
      queryCacheItems: audioProject.query_cache_items,
      styleLabelVersionList: audioProject.style_label_version_list,
    })

    const projectData = projectDetail[`v${projectDetail.version}`]
    const projectUid = projectId

    this._store.dispatch(this.getVideoEditorStoreItem('initializeVideoProjectExistAudio'), {
      $http: this._context.$typecast.$http,
      projectUid,
      projectData,
      projectName: projectDetail.name,
      projectVersion: projectDetail.version,
      mediaType: projectDetail.media_type,
      subtitle: projectDetail.subtitle,
      bgColor: projectDetail.bg_color,
    })

    const isAutoSave = !isAnonymousPage() && !projectDetail.share.user_list.length
    this.setIsAutoSave(isAutoSave)
    return projectDetail
  }

  setProjectBaseInfo({projectTitle, projectId, projectRevision, projectShare, projectLastModified, projectFolderId}) {
    if (projectTitle) {
      this._store.commit(this.getEditorStoreItem('setProjectTitle'), projectTitle)
    }
    if (projectId) {
      this._store.commit(this.getEditorStoreItem('setProjectId'), projectId)
    }
    if (projectRevision) {
      this._store.commit(this.getEditorStoreItem('setProjectRevision'), projectRevision)
    }
    if (projectShare) {
      this._store.commit(this.getEditorStoreItem('setProjectShare'), projectShare)
    }
    if (projectLastModified) {
      this._store.commit(this.getEditorStoreItem('setProjectLastModified'), projectLastModified)
    }
    if (projectFolderId) {
      this._store.commit(this.getEditorStoreItem('setProjectFolderId'), projectFolderId)
    }
  }

  async setProjectDetail({document, queryCacheItems, styleLabelVersionList}) {
    return new Promise((resolve, reject) => {
      this._store
        .dispatch(this.getQueryCacheStoreItem('loadAudioFromCache'), queryCacheItems)
        .then(() => {
          this._store.commit(this.getEditorStoreItem('setLoadedContent'), document)
          this._store.commit(this.getQueryCacheStoreItem('setStyleLabelVersionListRaw'), styleLabelVersionList)
        })
        .then(this._store.dispatch(this.getQueryCacheStoreItem('parseTiptap'), document))
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  setEditorViewWithLoadedContent(document) {
    const doc = this._editor.view.state.schema.nodeFromJSON(document)
    const view = this._editor.view
    const newState = EditorState.create({
      schema: view.state.schema,
      doc,
      plugins: view.state.plugins,
    })
    view.updateState(newState)
    this._store.commit(`${this._storeKey.editor}/setTiptapContent`, document)
    this._store.commit(
      this.getEditorStoreItem('setSavedProjectPayloadString'),
      JSON.stringify(this._store.getters[this.getEditorStoreItem('projectPayload')]),
    )
    this.onEditorUpdate(document)
  }

  async _saveCurrentProject() {
    if (!this._currentProjectId) {
      return
    }

    await this.saveProject({
      title: this._currentProjectTitle,
      projectId: this._currentProjectId,
    })
  }

  /**
   * @function createText
   * @description 입력받은 문장을 에디터에 추가한 후 고유 ID를 리턴. 고유 ID는 이후 해당 문장을 다시 불러올 때 load 함
   * @param {Object} createTextParam
   * @param {String} [createTextParam.title] 프로젝트 명. 없을 경우 내용 일부를 잘라서 사용
   * @param {String} createTextParam.text 에디터에 생성할 텍스트
   * @return {String} 텍스트 별 고유 ID
   */
  async createText({title, text}) {
    try {
      if (!title) {
        title = text.trim().slice(0, 50)
      }
      await this._saveCurrentProject()
      this.clearContent()
      this.setTextContents(text)
      const newProjectId = await this.saveProject({
        title: title,
      })
      this._store.commit(this.getQueryCacheStoreItem('resetPlayBuffer'))
      this._currentProjectTitle = title
      this._currentProjectId = newProjectId
      return newProjectId
    } catch (error) {
      return error
    }
  }

  clearContent() {
    this._editor.clearContent(true)
  }

  setAutoSaveTrigger() {
    if (config.feature.saveTemporary) {
      if (this._isAutoSave) {
        this.startAutoSaveTimer()
      } else {
        this.clearAutoSaveTimer()
      }
    }
  }

  isEditorModified() {
    return this._store.getters['typecast/editor/isEditorModified']
  }

  clearAutoSaveTimer() {
    if (this._autoSaveUpdateTimer) {
      clearTimeout(this._autoSaveUpdateTimer)
      this._autoSaveUpdateTimer = null
    }
  }

  startAutoSaveTimer() {
    this.clearAutoSaveTimer()

    this._autoSaveUpdateTimer = setTimeout(async () => {
      if (!this._editor) {
        this.clearAutoSaveTimer()
        return
      }
      if (!this._isAutoSave) {
        this.clearAutoSaveTimer()
        return
      }
      if (this._isProjectLoading) {
        this.clearAutoSaveTimer()
        return
      }
      if (this._store.state.typecast.editor.disableAutoSave) {
        this.clearAutoSaveTimer()
        return
      }

      if (!this.isEditorModified()) {
        this.clearAutoSaveTimer()
        return
      }

      this._store.commit(this.getEditorStoreItem('setIsSaving'), true)
      try {
        const projectId = this._store.getters[this.getEditorStoreItem('projectId')]
        await this.saveProject({
          title: this._store.getters[this.getEditorStoreItem('projectTitle')],
          projectId,
        })
      } catch (error) {
        this._context.notify({
          group: 'main',
          type: 'error',
          title: 'Failed!',
          text: i18n.t('editor_header.project_save_error'),
        })
      }
      this._store.commit(this.getEditorStoreItem('setIsSaving'), false)
    }, 2000)
  }
  setIsAutoSave(value) {
    this._isAutoSave = value
    if (this._isAutoSave) {
      this.setEditorWatch()
    } else {
      this.clearEditorWatch()
    }
  }

  setIsProjectLoading(value) {
    this._isProjectLoading = value
  }

  /**
   * @function setDefualtQueryAttr
   * @description 캐릭터 별 스피드, 스타일 기본값 설정
   * @param {Object} setDefaultQueryParams
   * @param {Number} setDefaultQueryParams.silence
   * @param {Number} setDefaultQueryParams.speed
   * @param {String} setDefaultQueryParams.actor
   */
  setDefaultQueryAttr({silence, speed, actor}) {
    try {
      const defaultQueryAttr = JSON.parse(localStorage.getItem('DEFAULT_QUERY_ATTR')) || {}
      if (!defaultQueryAttr[actor]) {
        defaultQueryAttr[actor] = {}
      }
      defaultQueryAttr[actor].silence = silence
      defaultQueryAttr[actor].speed = speed
      localStorage.setItem('DEFAULT_QUERY_ATTR', JSON.stringify(defaultQueryAttr))
    } catch (error) {
      console.error(error)
    }
  }

  /**
   * @function clearDefaultQueryAttr
   * @description 캐릭터 별 스피드, 스타일 기본값 설정
   * @param {Object} clearDefaultQueryParams
   * @param {String} clearDefaultQueryParams.actor
   */
  clearDefaultQueryAttr({actor}) {
    try {
      const defaultQueryAttr = JSON.parse(localStorage.getItem('DEFAULT_QUERY_ATTR')) || {}
      if (defaultQueryAttr[actor]) {
        delete defaultQueryAttr[actor]
      }
      localStorage.setItem('DEFAULT_QUERY_ATTR', JSON.stringify(defaultQueryAttr))
    } catch (error) {
      console.error(error)
    }
  }

  /**
   * @function getDefaultActor
   * @description 스크립트의 기본 캐릭터 가져오기
   * @return {Actor} actor
   */
  getDefaultActor() {
    return this._store.getters[this.getActorStoreItem('candidateActor')][0]
  }

  getActorAttribute(actorId, {style}) {
    let actor = this._store.getters[this.getActorStoreItem('candidateActor')].find(actor => actor.actor_id === actorId)
    if (!actor) {
      actor = this._store.getters[this.getActorStoreItem('candidateActor')][0]
    }

    const styleLabelVersionList = this._store.getters[this.getQueryCacheStoreItem('styleLabelVersionList')]
    const styleListByVersion = getCurrentActorStyleLabel(styleLabelVersionList, actor) ?? getLastActorStyleLabel(actor)
    // Array.flat polyfill for samsung internet
    const currentActorStyleList = Object.values(styleListByVersion.data).reduce((acc, val) => acc.concat(val), [])
    return {
      hasStyle: currentActorStyleList.includes(style),
      isDisabledSpeed: this.isDisabledSpeedSetting(actor),
      isDisabledCustomSpeed: this.isDisabledCustomSpeedSetting(styleListByVersion.flags),
    }
  }

  isDisabledSpeedSetting(actor) {
    return !(actor.tuning && actor.tuning.includes('speed'))
  }

  isDisabledCustomSpeedSetting(flags) {
    return !flags.includes('speaking-duration')
  }

  hasCandidateActor(actorId) {
    const candidateActor = this._store.getters[this.getActorStoreItem('candidateActor')]
    const hasActor = !!candidateActor.find(actor => actor.actor_id === actorId)

    return {
      hasActor,
      willChange: hasActor ? null : candidateActor[0],
    }
  }

  setFocusToQuery(queryId) {
    const tr = this._editor.state.tr
    const docContent = this._editor.state.doc.content.content
    const docContentLength = docContent.length
    let beforeContentSize = 0
    for (let i = 0; i < docContentLength; i++) {
      const paragraph = docContent[i].content.content
      const paragraphLength = paragraph.length
      for (let j = 0; j < paragraphLength; j++) {
        const node = paragraph[j]
        beforeContentSize += node.nodeSize
        if (node.type.name === 'text') {
          const mark = node.marks[0]
          if (mark.attrs.id === queryId) {
            this._editor.view.dispatch(
              tr.setSelection(TextSelection.create(tr.doc, beforeContentSize)).scrollIntoView(),
            )
            this._editor.view.focus()
            return
          }
        }
      }
      beforeContentSize += 2
    }
  }

  isProjectSaving() {
    return this._isProjectSaving
  }
}

import '@/scss/_custom-bootstrap-variables.scss'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/scss/_custom-bootstrap.scss'

import {BFormCheckbox, BFormCheckboxGroup} from 'bootstrap-vue/esm/components/form-checkbox'
import {BFormGroup} from 'bootstrap-vue/esm/components/form-group'
import {BProgress} from 'bootstrap-vue/esm/components/progress'
import {BFormSelect} from 'bootstrap-vue/esm/components/form-select'
import {BForm, BFormInvalidFeedback} from 'bootstrap-vue/esm/components/form'
import {BFormInput} from 'bootstrap-vue/esm/components/form-input'
import {BPagination} from 'bootstrap-vue/esm/components/pagination'

export default function init(Vue) {
  const components = [
    BPagination,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BFormSelect,
    BProgress,
    BFormGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
  ]

  components.forEach(comp => Vue.component(comp.extendOptions?.name || comp.name, comp))
}

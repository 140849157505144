import * as dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import utc from 'dayjs/plugin/utc'
import {i18n} from '@/i18n'
dayjs.extend(duration)
dayjs.extend(utc)

function getTimeBySecond(second, format) {
  const addZeroOneDigit = format.addZeroOneDigit || false
  const noSpacing = format.noSpacing || false
  const showZeroHours = format.hasOwnProperty('showZeroHours') ? format.showZeroHours : true
  let leftSecond = Math.ceil(second % 60)
  let minutes = second >= 60 ? Math.floor(second / 60) : 0
  let hour = 0
  let message = ''
  if (leftSecond >= 60) {
    leftSecond = 0
    minutes += 1
  }
  if (minutes > 60) {
    hour = Math.floor(minutes / 60)
    minutes = minutes % 60
  }
  const haveHour = hour > 0
  const haveMinutes = minutes > 0
  const haveSeconds = leftSecond > 0
  if (addZeroOneDigit) {
    if (leftSecond < 10) {
      leftSecond = `0${leftSecond}`
    }
    if (minutes < 10) {
      minutes = `0${minutes}`
    }
    if (hour < 10) {
      hour = `0${hour}`
    }
  }
  if ((addZeroOneDigit && showZeroHours) || haveHour) {
    message = `${hour}${format.hours}`
  }
  if (addZeroOneDigit || haveMinutes) {
    message = `${message} ${minutes}${format.minutes}`
  }
  if (addZeroOneDigit || haveSeconds) {
    message = `${message} ${leftSecond}${format.seconds}`
  }
  if (noSpacing) {
    message = message.replace(/\s/gi, '')
  }
  if (hour === 1) {
    message = message.replace(/(hrs)/gi, 'hr')
  }
  if (minutes === 1) {
    message = message.replace(/(mins)/gi, 'min')
  }
  return i18n.locale === 'en' ? findStringIndex(message || 0) : message || 0
}

/**
 * insert a space after number
 * @param {String} timeString ex) '5hrs 59mins 31sec'
 * @returns {String} ex) '5 hrs 59 mins 31 sec'
 */
function findStringIndex(timeString) {
  if (!timeString || Number(timeString)) {
    return timeString
  }
  const regex = /\D+/g
  const matches = [...timeString.matchAll(regex)]
  matches.forEach(match => {
    timeString = timeString.replace(new RegExp(`${match[0]}`), ` ${match[0]}`)
  })
  return timeString
}

function getCurrentTimeInOffset(offset) {
  const utcNow = dayjs().utc()
  return utcNow.add(offset, 'hour')
}

function getOffsetTime(timestamp) {
  if (!timestamp) {
    return Date.now()
  }
  // 서버에서 UTC(+00:00)를 KST(+09:00)로 착각해서 UTC에 9시간을 뺀 timestamp를 반환한다.
  // 그러므로 백엔드에서 잘못 반환된 timestamp는 UTC(+00:00)도 아니고 KST(+09:00)도 아닌 HAST(-09:00)이다. 이걸 보정하기 위해 9시간을 더한다.
  return new Date(timestamp + 9 * 60 * 60 * 1000).getTime()
}

function getStringDate(timestamp, language, notModify) {
  let miliseconds = timestamp
  if (!notModify) {
    miliseconds = getOffsetTime(timestamp)
  }

  const locales = language === 'ko' ? 'ko-KR' : 'en-Us'
  const options = {year: 'numeric', month: 'short', day: '2-digit'}

  const isDev = process.env.NODE_ENV === 'development'
  if (isDev) {
    return new Date(miliseconds).toLocaleTimeString(locales, options)
  } else {
    return new Date(miliseconds).toLocaleDateString(locales, options)
  }
}

function getOrdinalDate(date, language) {
  if (language === 'ko') {
    return date + '일'
  }

  const lastNum = date.toString().split('').pop()

  switch (lastNum) {
    case '1':
      return date + 'st'
    case '2':
      return date + 'nd'
    case '3':
      return date + 'rd'
    default:
      return date + 'th'
  }
}

function getLastEditPeriod(milliSec) {
  const now = Date.now()
  const min = 1000 * 60
  const hour = min * 60
  const day = hour * 24
  const week = day * 7
  const month = week * 4
  const year = month * 12
  const timeDiff = now - getOffsetTime(milliSec)

  let number = 0
  const isNumber1 = () => (number === 1 ? 'a_' : '')

  switch (true) {
    case timeDiff > year:
      number = Math.floor(timeDiff / year)
      return {number, period: `${isNumber1()}year_ago`}
    case timeDiff > month:
      number = Math.floor(timeDiff / month)
      return {number, period: `${isNumber1()}month_ago`}
    case timeDiff > day:
      number = Math.floor(timeDiff / day)
      return {number, period: `${isNumber1()}day_ago`}
    case timeDiff > hour:
      number = Math.floor(timeDiff / hour)
      return {number, period: `${isNumber1()}hour_ago`}
    case timeDiff > min:
      number = Math.floor(timeDiff / min)
      return {number, period: `${isNumber1()}min_ago`}
    default:
      return {number, period: 'just_now'}
  }
}

function getDigitalTime(num) {
  if (!num) {
    return '00:00'
  }
  const secNum = parseInt(num, 10)
  let hours = Math.floor(secNum / 3600)
  let minutes = Math.floor((secNum - hours * 3600) / 60)
  let seconds = secNum - hours * 3600 - minutes * 60

  if (hours < 10 && hours > 0) {
    hours = '0' + hours
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }
  if (hours) {
    return hours + ':' + minutes + ':' + seconds
  }
  return minutes + ':' + seconds
}

function getSrtTimeFormat(num) {
  if (!num) {
    return '00:00:00,000'
  }
  const secNum = parseFloat(num)

  const hours = Math.floor(secNum / 3600)
  const minutes = Math.floor((secNum - hours * 3600) / 60)
  const seconds = secNum - hours * 3600 - minutes * 60

  const hh = hours.toString().padStart(2, '0')
  const mm = minutes.toString().padStart(2, '0')
  const ss = `${seconds < 10 ? '0' : ''}${seconds.toFixed(3).replace('.', ',')}`

  return `${hh}:${mm}:${ss}`
}

function getDigitalTimeWithMiliseconds(num) {
  if (!num) {
    return '00:00.000'
  }
  const durationObject = dayjs.duration(Math.floor(num * 1000))
  return durationObject.format('mm:ss.SSS')
}

function getCurrentTimestamp() {
  return dayjs().unix() * 1000
}

export {
  getTimeBySecond,
  getCurrentTimeInOffset,
  getOffsetTime,
  getStringDate,
  getOrdinalDate,
  getLastEditPeriod,
  getDigitalTime,
  getSrtTimeFormat,
  getCurrentTimestamp,
  getDigitalTimeWithMiliseconds,
}

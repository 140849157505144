import TButton from './components/Button/Button.vue'
import TCheckbox from './components/Checkbox/Checkbox.vue'
import TRadio from './components/Radio/Radio.vue'
import TToggleButton from './components/ToggleButton/ToggleButton.vue'
import TIcon from './components/Icons/Icon.vue'
import TTag from './components/Tag/Tag.vue'
import TFormControl from './components/Form/FormControl.vue'
import TFormLabel from './components/Form/FormLabel.vue'
import TFormHelperMessage from './components/Form/FormHelperMessage.vue'
import TFormErrorMessage from './components/Form/FormErrorMessage.vue'
import TFormTelephoneField from './components/Form/FormTelephoneField.vue'
import TFormField from './components/Form/FormField.vue'
import TInputText from './components/Input/InputText.vue'
import TInputTel from './components/Input/InputTel.vue'
import TSpinner from './components/Spinner/Spinner.vue'
import TSelect from './components/Select/Select.vue'

const install = Vue => {
  Vue.component('TButton', TButton)
  Vue.component('TCheckbox', TCheckbox)
  Vue.component('TRadio', TRadio)
  Vue.component('TToggleButton', TToggleButton)
  Vue.component('TIcon', TIcon)
  Vue.component('TTag', TTag)
  Vue.component('TFormControl', TFormControl)
  Vue.component('TFormLabel', TFormLabel)
  Vue.component('TFormHelperMessage', TFormHelperMessage)
  Vue.component('TFormErrorMessage', TFormErrorMessage)
  Vue.component('TFormField', TFormField)
  Vue.component('TInputText', TInputText)
  Vue.component('TInputTel', TInputTel)
  Vue.component('TFormTelephoneField', TFormTelephoneField)
  Vue.component('TSpinner', TSpinner)
  Vue.component('TSelect', TSelect)
}

export default {
  install,
}

export {TButton}
export {TCheckbox}
export {TRadio}
export {TIcon}
export {TTag}
export {TFormControl}
export {TFormLabel}
export {TFormHelperMessage}
export {TFormErrorMessage}
export {TFormField}
export {TInputText}
export {TInputTel}
export {TFormTelephoneField}
export {TSpinner}
export {TSelect}

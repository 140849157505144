/* eslint-disable camelcase */
// eslint-disable-next-line

import {ProjectFactory} from 'light'
import {encodeUrlParams} from '@/utils/backend-utils'
import {ACTOR_URL, ANONYMOUS_TEMPLATE_URL} from './video-api-url'
import {isStyleLabelSSFM, isCustomActor} from '@/libs/modules/actor/actor-version-helpers'

const backendApi = {
  // version
  v,
  // me
  me,
  myMetrics,
  resetPassword,
  terminationMe,
  putMeActivation,
  // speak
  speak,
  speakDetail,
  speakBatch,
  speakDetailBatch,
  speakQBatch,
  speakDownloadBatch,
  speakEstimateDurationBatch,
  speakShareBatch,
  // actor
  prosodyActorAll,
  getActor,
  getActorPackages,
  // project
  updateProjectName,
  cloneProject,
  getProjectTemplate,
  getAnonymousTemplate,
  saveProject,
  createProject,
  deleteProject,
  loadProjectDetailV2,
  importProject,
  putImportProject,
  getImportProjectStatus,
  getProjectHistoryList,
  getProjectHistoryDetail,
  httpErrorMsg,
  // project - foldering
  createFolder,
  getFolderList,
  updateFolderName,
  deleteFolder,
  getSharedProjectList,
  getProjectList,
  moveProjectToFolder,
  // plan
  getSubscribePlan,
  getCustomPlan,
  getPlanByPackageId,
  //payment
  getPaymentErrorMessage,
  postPaymentCard,
  getPayment,
  cancelPayment,
  resubscribePayment,
  cancelDowngrade,
  changePayment,
  changePayForKakao,
  changePayForKakaoDirect,
  postVolumeUp,
  putVolumeUpReserve,
  putVolumeDown,
  cancelReservedVolume,
  reservePlanUpgrade,
  cancelReservedPlanUpgrade,
  // me
  getUserDownload,
  getUserDownloadVideo,
  putMe,
  //ml
  splitText,
  splitTextList,
  // File
  uploadFileToS3,

  // register
  getIsUnderage,
  getGuardianEmailVerification,
  sendGuardianEmailVerification,
  sendGuardianAgreement,
  updateGuardianEmail,

  // Groups
  getGroupInfo,
  inviteGroup,
  getInviteTokenInfo,
  deleteGroupMember,
  acceptInviteGroup,
  cancelInviteGroup,
  resendInviteMail,
  shareProjectToGroupMember,
  updateShareProjectToGroupMember,
  getProjectRevision,
  getProjectDetail,

  //Credit
  getCreditInfo,

  //Recommend
  getActorRecommendList,
  patchActorRecommendList,

  // Comment
  getThreadList,
  createComment,
  deleteComment,
  updateThreadStatus,

  // Youtube
  postYoutubeAuth,
  getYoutubeToken,
  deleteYoutubeAuth,
  requestYoutubeDirectUpload,
  getYoutubeStatus,

  // user-country
  getUserCountry,

  // new features
  getFeatureNews,

  // coupon
  getCouponList,
  getCouponItem,

  //back-download
  getBackDownloadList,
  deleteBackDownloadList,

  // user actor_meta
  getUserActorMeta,
  updateUserActorMeta,

  // chat gpt
  postAiChatMessage,
  createNewAiConversation,
  getCurrentProjectAiConversationId,
  getAiConversationMessages,
  getAiChatMessage,
  stopAiMessageGeneration,

  // srt
  uploadSrtFile,

  // audio mix
  postAudioMix,
  getAudioMix,

  // ip whitelists
  getIpWhitelists,
  createIpWhitelist,
  updateIpWhitelist,
  deleteIpWhitelist,

  // data retention
  getDataRetentionSetting,
  updateDataRetentionSetting,
}

function postYoutubeAuth($http, payload) {
  const url = `/api/me/youtube-auth`
  return $http
    .post(url, payload, {
      headers: {
        'X-Requested-With': 'XmlHttpRequest',
      },
    })
    .then(res => res.data.result)
}

function getYoutubeToken($http) {
  const url = `/api/me/youtube-token`
  return $http.get(url).then(res => res.data.result)
}

function deleteYoutubeAuth($http) {
  const url = `/api/me/youtube-auth`
  return $http.delete(url).then(res => res.data.result)
}

function requestYoutubeDirectUpload($http, downloadId, payload) {
  const url = `/api/video/${downloadId}/youtube-upload`
  return $http.post(url, payload).then(res => res.data.result)
}

function getYoutubeStatus($http, url) {
  return $http.get(url).then(res => res.data.result)
}

function httpErrorMsg(error) {
  if (error.response) {
    if (error.response.data.message) {
      return error.response.data.message.msg + ' (' + error.response.data.message.error_code + ')'
    } else {
      return `status code: ${error.response.status}`
    }
  } else {
    return JSON.stringify(error)
  }
}

function getPaymentErrorMessage(error, nsEvent) {
  let msg = ''

  if (error.response?.data?.message) {
    if (error.response.data.message.msg) {
      msg = error.response.data.message.msg.replace('iamport error -1: ', '')
    } else {
      msg = error.response.data.message
    }
  } else if (error.pg_provider === 'kakaopay' && error.error_msg) {
    msg = error.error_msg
  }

  if (nsEvent) {
    nsEvent.event({category: 'payment', action: 'failed_payment', label: {reason: msg}})
  }

  return msg
}

function v($http) {
  const url = `/api/v`
  return $http.get(url).then(res => res.data)
}

function me($http) {
  const url = `/api/me`
  return $http.get(url).then(res => res.data.result)
}

function myMetrics($http) {
  const url = `/api/me/metrics`
  return $http.get(url).then(res => res.data.result)
}

function resetPassword($http, password, newPassword) {
  const url = `/api/me/reset_password`
  return $http
    .post(url, {
      password: password,
      'new-password': newPassword,
    })
    .then(res => res.data.result)
}

function terminationMe($http, reason, memo) {
  const url = `/api/me/termination`
  return $http
    .post(url, {
      reason: {
        type: reason,
        memo: memo,
      },
    })
    .then(res => res.data.result)
}

function speak($http, query) {
  const url = `/api/speak`
  return $http.post(url, query).then(res => res.data.result)
}

function speakDetail($http, url) {
  return $http.get(url).then(res => res.data.result)
}

function speakBatch($http, queryList) {
  const url = `/api/speak/batch/post`
  return $http.post(url, queryList).then(res => res.data.result)
}

function speakDetailBatch($http, urlList) {
  const url = `/api/speak/batch/get`
  return $http.post(url, urlList).then(res => res.data.result)
}

function prosodyActorAll($http) {
  const url = ACTOR_URL
  return $http
    .get(url, {
      params: {
        role: 'prosody',
      },
    })
    .then(res => {
      const isKorean = actor => actor.language === 'ko-kr'
      const isSSFMOnly = actor => actor.style_label_v2.every(isStyleLabelSSFM)

      const shouldBeHidden = actor => !isCustomActor(actor) && isKorean(actor) && isSSFMOnly(actor)

      return res.data.result.filter(actor => !shouldBeHidden(actor))
    })
}

function getActor($http, actorId) {
  const url = `${ACTOR_URL}/${actorId}`
  return $http.get(url).then(res => res.data.result)
}

/**
 * @param {*} $http
 * @param {('ko'|'en')} locale
 * @returns {Array<{
 *  _id: string
 *  is_released: boolean
 *  created_at: number
 *  released_at: number
 *  release_end_at: number | null
 *  language: 'ko' | 'en'
 *  description: string
 *  actor_list: string[]
 *  icon_url: string}>}
 */
function getActorPackages($http, locale) {
  const url = `/api/actor-packages`
  return $http.get(url, {params: {language: locale, limit: 999}}).then(res => res.data.result)
}

function speakQBatch($http, urlList, quality) {
  const url = `/api/speak/batch/q`
  return $http.post(url, {query_list: urlList, quality: quality}).then(res => res.data)
}

function speakDownloadBatch($http, urlSilenceList) {
  const url = `/api/speak/batch/download/v2`
  return $http.post(url, urlSilenceList).then(res => res.data.result)
}

function speakEstimateDurationBatch($http, queryList) {
  const url = `/api/speak/batch/download/v2/estimate-texts`
  return $http.post(url, queryList).then(res => res.data)
}

function speakShareBatch($http, title, urlSilenceList) {
  const url = `/api/speak/batch/share`
  return $http.post(url, {title: title, query_list: urlSilenceList}).then(res => res.data)
}

function cloneProject($http, oid) {
  const url = `/api/project/${oid}/clone-for-user`
  return $http.post(url).then(res => res.data)
}

/**
 * /project/{oid} - put: 프로젝트 이름변경
 * @param {*} $http
 * @param {String} oid The project id to be changed
 * @param {String} name new project name
 */
function updateProjectName($http, oid, name) {
  const url = `/api/project/${oid}`
  return $http.put(url, {name}).then(res => res.data)
}

function getProjectTemplate($http) {
  const url = `/api/project/template`
  return $http
    .get(url, {
      params: {
        limit: 50,
      },
    })
    .then(res => res.data)
}

function getAnonymousTemplate($http, category, language) {
  const url = `${ANONYMOUS_TEMPLATE_URL}/${category}/noauth`
  return $http
    .get(url, {
      params: {
        language,
      },
    })
    .then(res => res.data.result)
}

function saveProject($http, project, oid) {
  const url = `/api/project/${oid}`
  return $http.put(url, project).then(res => res.data)
}

/**
 *
 * @param {*} $http
 * @param {*} project
 * template - project: {name: String, template_id: String, version: Number}
 * not template - project: {name: String, oid: String, folder_id: String, media_type: String, v10:
 * {
 *  tiptap: Object || null,
 *  query_cache_items: Object || {},
 *  style_label_version_list: Array || [],
 *  slide_list: Array || [],
 *  additional_data: Object || {}}}
 */
function createProject($http, project) {
  const url = `/api/project`
  return $http.post(url, project).then(res => res.data)
}

/**
 * /api/project/import - post: 파일 import 하여 프로젝트 생성
 * @param {*} $http
 * @param {*}
 * {
 *  type: imported type [file, url],
 *  project_name,
 *	file_name: file 일 때 required,
 *  url: url 일 때 required,
 *	folder_id,
 *  media_type: [audio, shorts]
 * }
 * @returns {Object} {result: {import_presigned_url, project_import_url}}
 * import_presigned_url: group(team) 계정이거나 paid::import 권한을 가진 유저만 가능
 */
function importProject($http, {type, project_name, file_name, folder_id, url, media_type = 'audio'}) {
  const apiUrl = `/api/project/import`
  return $http.post(apiUrl, {type, project_name, file_name, folder_id, url, media_type}).then(res => res.data.result)
}

function putImportProject($http, {projectImportUrl}) {
  return $http.put(projectImportUrl).then(res => res.data.result)
}
function getImportProjectStatus($http, {projectImportUrl}) {
  return $http.get(projectImportUrl).then(res => res.data.result)
}
/**
 * @see https://create-test.icepeak.ai/api/doc/swagger#/project/get_project_history
 *
 * !!NOTE: page는 1부터 시작함에 유의
 */
function getProjectHistoryList($http, projectId, page, limit) {
  const apiUrl = `/api/project/${projectId}/history`
  return $http
    .get(apiUrl, {
      params: {
        page,
        limit,
      },
    })
    .then(res => ({
      page: res.data.page,
      result: res.data.result,
    }))
}

function getProjectHistoryDetail($http, projectId, historyId) {
  const apiUrl = `/api/project/${projectId}/history/${historyId}`
  return $http.get(apiUrl).then(res => res.data.result)
}

function overwriteProject($http, oid, name, queryList, styleList) {
  const url = `/api/project/${oid}`
  return $http.put(url, {name: name, query_list: queryList, style_list: styleList}).then(res => res.data)
}

/**
 * /project/many/delete - post: 다수의 프로젝트 또는 한개 삭제
 * @param {*} $http
 * @param {Array} projectIds Project ids to delete (at least 1)
 * {
 *   "project_ids": ["projectId"]
 * }
 */
function deleteProject($http, projectIds) {
  const url = `/api/project/many/delete`
  return $http.post(url, projectIds).then(res => res.data)
}

/**
 *
 * @param {*} $http
 * @param {*} projectIds Project ids to move to another folder (at least 1)
 * @param {*} folderId New folder id
 */
function moveProjectToFolder($http, projectIds, folderId) {
  const url = `/api/project/many/move`

  return $http
    .post(url, {
      project_ids: projectIds,
      folder_id: folderId,
    })
    .then(res => res.data)
}

/**
 * /project/folder - post: 생성
 * @param {*} $http
 * @param {String} name Folder name
 * @returns
 * {
 *    project_folder: { _id: String, name: String }
 * }
 */
function createFolder($http, name) {
  const url = `/api/project/folder`
  return $http.post(url, {name}).then(res => res.data.result)
}

/**
 * /project/folder - get: '내프로젝트' 하위 폴더리스트
 * @param {*} $http
 * @param {String} folderSortBy Sort type
 */
function getFolderList($http, folderSortBy) {
  const url = `/api/project/folder`
  return $http
    .get(url, {
      params: {
        sort: folderSortBy,
      },
    })
    .then(res => res.data.result)
}

/**
 * /project/folder/{oid} - put: 폴더이름변경
 * @param {*} $http
 * @param {String} oid The folder id to be changed
 * @param {String} name new folder name
 */
function updateFolderName($http, oid, name) {
  const url = `/api/project/folder/${oid}`
  return $http.put(url, {name}).then(res => res.data)
}

/**
 * /project/folder/{oid} - delete: 폴더삭제
 * @param {*} $http
 * @param {String} oid The folder id to be deleted
 */
function deleteFolder($http, oid) {
  const url = `/api/project/folder/${oid}`
  return $http.delete(url).then(res => res.data)
}

/**
 * /project/v2/share - get: 공유받은 프로젝트
 * @param {*} $http
 * @param {*} options
 * {
 *   page: page number(default=1)
 *   limit: 50,
 *   name: search name,
 *   sort: sort key [last_modified(default), _id, last_opened, name]
 * }
 */
function getSharedProjectList($http, options = {}) {
  const url = `/api/project/v2/share`
  return $http.get(url, {params: options}).then(res => {
    return {
      ...res.data,
      result: res.data.result.map(project => ProjectFactory.factory(project)),
    }
  })
}

/**
 * /project/v2 - get: 내 프로젝트(미분류)
 * @param {*} $http
 * @param {Object} options (optional)
 * {
 *    page : page number(default=1)
 *    limit : imit page(default=10)
 *    name : search name
 *    admin : with admin privilege, 1 or 0
 *    user_id : uid
 *    sort : sort key [last_modified(default), _id, last_opened, name]
 *    folder_id : folder_id
 *    project_type: 'any' (ignore folder_id)
 * }
 * @param {String} folderId The folder id to search (optional)
 */
function getProjectList($http, options = {}, folderId = '') {
  const url = `/api/project/v2?folder_id=${folderId}`
  return $http.get(url, {params: options}).then(res => {
    return {
      ...res.data,
      result: res.data.result.map(project => ProjectFactory.factory(project)),
    }
  })
}

function loadProjectDetailV2($http, oid) {
  const url = `/api/project/v2/${oid}`
  return $http.get(url).then(res => res.data.result)
}

/**
 * @param {*} $http
 * @returns {Object}
 * {
    "result": [
      {
        "name": "string",
        "seconds": 0,
        "yearly": {
          "amount": [
            {
              "currency": "string", # krw or usd
              "amount": 0,
              "promotion": {
                "amount": 0,
                "period": 0,
                "discount_rate": "string",
                "currency": "string" # krw or usd
              },
              "amount_monthly": 0, # 할인전 한달 가격(기존 월결제 가격)
              "amount_monthly_discount": 0, # 할인이 된 한달 가격
              "amount_discount_rate": "string"
            }
          ],
          "member_limit": 0, # team 플랜일 경우
          "name": "string",
          "package_id": "string",
          "period": "string", # monthly or yearly
          "seconds": 0
        },
        "monthly": {
          "amount": [
            {
              "currency": "string",
              "amount": 0,
              "promotion": {
                "amount": 0,
                "period": 0,
                "discount_rate": "string",
                "currency": "string"
              },
              "amount_monthly": 0,
              "amount_monthly_discount": 0,
              "amount_discount_rate": "string"
            }
          ],
          "member_limit": 0,
          "name": "string",
          "package_id": "string",
          "period": "string",
          "seconds": 0
        }
      }
    ]
 * }
 */
function getSubscribePlan($http, version = 'v3') {
  const url = `/api/price/${version}/subscription`
  return $http.get(url).then(res => res.data.result)
}

/**
 * get custom plan
 * @param {*} $http
 * @param {String} code business payment code
 * @returns {Object}
 * {
    package_id
    promotion_id
    uid
    _id: {
      $oid
      created_date
    }
 * }
 */
function getCustomPlan($http, code) {
  const url = `/api/payment/code/${code}`
  return $http.get(url).then(res => res.data.result)
}

/**
 * get plan by package_id
 * @param {*} $http
 * @param {*} packageId
 * @returns {Object}
 * {
    total_amount
    enterprise
    period
    seconds
    activation
    actor_list
    country
    default_actor_list_name
    enable_api
    group
    ice
    member_limit
    name
    package_api_url
    roles
    _id
 * }
 */
function getPlanByPackageId($http, packageId) {
  const url = `/api/package/${packageId}`
  return $http.get(url).then(res => res.data.result)
}

/**
 * /tc-payment/invoice/card - post: 카드 등록
 * @param {*} $http
 * @param {*} card
 * {
 *   "package_id": "string",
 *   "pg": "string",
 *   "card_number": "string",
 *   "expiry": "string",
 *   "birth": "string",
 *   "pwd_2digit": "string",
 *   "email": "string",
 *   "phone": "string"
 * }
 */
function postPaymentCard($http, card) {
  const url = `/tc-payment/invoice/card`
  return $http.post(url, card).then(res => res.data)
}

/**
 * /tc-payment/invoice - get: 지불정보 리스트
 * @param {*} $http
 */
function getPayment($http, page) {
  const url = `/tc-payment/invoice`
  return $http
    .get(url, {
      params: {
        page: page,
      },
    })
    .then(res => res.data)
}

/**
/tc-payment/invoice/unsub
- put: 구독 취소
*/
function cancelPayment($http) {
  const url = `/tc-payment/invoice/unsub`
  return $http.put(url).then(res => res.data)
}

/**
/tc-payment/invoice/cancel/unsub
- put: 구독해지 취소
*/
function resubscribePayment($http) {
  const url = `/tc-payment/invoice/cancel/unsub`
  return $http.put(url).then(res => res.data)
}

/**
 * /payment/cancel/downgrade
 * - put: 변경 취소
 * @param {*} $http
 */
function cancelDowngrade($http) {
  const url = `/tc-payment/invoice/cancel/plan-down`
  return $http.put(url).then(res => res.data)
}

/**
 * /payment/change - post: 플랜 변경
 * @param {*} $http
 * @param {*} newPayment
 * {
 *   "new_package_id": "string",
 *   "country_code": "KR" | "US",
 *   "promotion_id": "string"
 *   "pg": "kakaopay" // 카카오페이인 경우
 *   new_package_id 와 promition_id 중 한개의 parameter만 보내야함
 * }
 */
function changePayment($http, newPayment, retry) {
  const retryEndpoint = retry ? '/retry' : ''
  const url = `/tc-payment/invoice${retryEndpoint}`
  return $http.post(url, newPayment).then(res => res.data.result)
}

function changePayForKakao($http) {
  const url = `/api/payment/card/kakaopay`
  return $http.post(url).then(res => res.data)
}

function changePayForKakaoDirect($http, redirectUrl) {
  const url = `/tc-payment/invoice/card/kakaopay`
  return $http.post(url, {redirect_url: redirectUrl}).then(res => res.data)
}

/**
 * 사용량 추가 구매
 * @param {*} $http
 * @param {*} package_id
 * @param {*} volume_items {volume_id: businessInfo에서 찾을 수 있음, volume_types: 'seconds || member', quantity: 1}
 * @returns
 */
function postVolumeUp($http, package_id, volume_items) {
  const url = `/tc-payment/invoice/volume-up`
  return $http.post(url, {package_id, volume_items})
}

/**
 * 사용량 추가 구매 예약
 * @param {*} $http
 * @param {pg} string payment method
 * @param {country_code} string user's country code
 * @param {volume_items} array {volume_id, volume_types, quantity}
 * @returns {result: {type, date}}
 */
function putVolumeUpReserve($http, {pg, country_code, volume_items}) {
  const url = `/tc-payment/invoice/reservation/volume-up`
  return $http.post(url, {pg, country_code, volume_items}).then(res => res.data)
}

/**
 * 사용량 차감
 * @param {*} $http
 * @param {*} package_id
 * @param {*} volume_items
 * @returns
 */
function putVolumeDown($http, package_id, volume_items) {
  const url = `/tc-payment/invoice/volume-down`
  return $http.put(url, {package_id, volume_items})
}

/**
 * 사용량 차감 취소
 * @param {*} $http
 * @returns
 */
function cancelReservedVolume($http) {
  const url = `/tc-payment/invoice/cancel/volume`
  return $http.put(url)
}

/**
 * reserve plan upgrade
 */
function reservePlanUpgrade($http, newPayment) {
  const url = `/tc-payment/invoice/reservation/plan-up`
  return $http.post(url, newPayment).then(res => res.data)
}

/**
 * cancel scheduling of plan upgrade
 */
function cancelReservedPlanUpgrade($http) {
  const url = `/tc-payment/invoice/cancel/plan-up`
  return $http.put(url)
}

function getUserDownload($http, {page, limit = 20}) {
  const url = `/api/me/download`
  return $http
    .get(url, {
      params: {
        page,
        limit,
      },
    })
    .then(res => res.data)
}

function getUserDownloadVideo($http, {page, limit = 20, downloadIds = ''}) {
  const url = `/api/me/download/videos`
  return $http
    .get(url, {
      params: {
        page,
        limit,
        'download-ids': downloadIds,
      },
    })
    .then(res => res.data)
}

/**
 * /api/me - put: 해당 id me 업데이트
 * @param {*} $http
 * @param {*} me {fullname, phone, subscribe_email}
 */
function putMe($http, me) {
  const url = `/api/me`
  return $http.put(url, me).then(res => res.data)
}

function splitText($http, script) {
  const url = `/api/ml/split-text`
  return $http.post(url, {text: script})
}

function splitTextList($http, texts) {
  const url = `/api/ml/split-text-list`
  return $http.post(url, {texts: texts})
}

async function uploadFileToS3($http, {importPresignedUrl, file}) {
  // const binary = await file.arrayBuffer()
  const formData = new FormData()
  formData.append('file', file)
  return $http.put(importPresignedUrl, file, {
    headers: {
      'Content-type': `multipart/form-data`,
    },
  })
}

function getIsUnderage($http, birthday) {
  const url = `/api/guardian-check/underage?birth=${birthday}`
  return $http.get(url).then(res => res.data.result.underage)
}

function sendGuardianAgreement($http, token) {
  const url = `/api/guardian-check`
  return $http.post(url, {token}).then(res => res.data.result)
}

function getGuardianEmailVerification($http, guardian_email) {
  const url = `/api/guardian-check/email?email=${guardian_email}`
  return $http.get(url).then(res => res.data.result)
}

function sendGuardianEmailVerification($http, {email, guardian_email, provider, provider_uid}) {
  const url = `/api/guardian-check/email`
  return $http.post(url, {email, guardian_email, provider, provider_uid}).then(res => res.data.result)
}

function updateGuardianEmail($http, {token, guardian_email}) {
  const url = `/api/guardian-check/email`
  return $http.put(url, {token, guardian_email}).then(res => res.data.result)
}

function getGroupInfo($http, old) {
  const url = `/api/group/${old}`
  return $http.get(url).then(res => res.data.result)
}

function inviteGroup($http, emailList) {
  const url = `/api/group/invitation`
  return $http.post(url, {email_list: emailList}).then(res => res.data.result)
}

function getInviteTokenInfo($http, token) {
  if (!token) {
    return
  }
  const url = `/api/group/invitation`
  return $http
    .get(url, {
      params: {
        token,
      },
    })
    .then(res => res.data.result)
}

function acceptInviteGroup($http, inviteUrl) {
  return $http.put(inviteUrl).then(res => res.data.result)
}
function cancelInviteGroup($http, inviteUrl) {
  return $http.delete(inviteUrl).then(res => res.data.result)
}
function deleteGroupMember($http, memberUrl) {
  return $http.delete(memberUrl).then(res => res.data.result)
}
function resendInviteMail($http, url) {
  return $http.put(`${url}/resend`, {}).then(res => res.data.result)
}
function shareProjectToGroupMember($http, projectId, memberList) {
  const url = `/api/project/${projectId}/share`
  return $http
    .post(url, {
      user_ids: memberList,
    })
    .then(res => res.data.result)
}
function updateShareProjectToGroupMember($http, projectId, memberList) {
  const url = `/api/project/${projectId}/share`
  return $http
    .put(url, {
      user_ids: memberList,
    })
    .then(res => res.data.result)
}
function getProjectRevision($http, projectId, currentRevision) {
  const url = `/api/project/${projectId}/revision`
  return $http
    .get(url, {
      params: {
        revision: currentRevision,
      },
    })
    .then(res => res.data.result)
}

function getProjectDetail($http, projectUrl) {
  return $http.get(projectUrl).then(res => ProjectFactory.factory(res.data.result))
}

function getCreditInfo($http, creditId) {
  const url = `/api/credit/${creditId}`
  return $http.get(url).then(res => res.data.result)
}

function putMeActivation($http) {
  const url = `/api/me/activation`
  return $http.put(url).then(res => res.data)
}

function getActorRecommendList($http, script) {
  return $http({
    url: process.env.VUE_APP_ACTOR_RECOMMEND_URL,
    method: 'post',
    data: {
      text: script,
    },
  })
}

function patchActorRecommendList($http, logId, castActors) {
  return $http({
    url: process.env.VUE_APP_ACTOR_RECOMMEND_LOG_URL + `/${logId}`,
    method: 'patch',
    data: {
      cast_actors: castActors,
    },
  })
}

function getThreadList($http, projectId) {
  const url = `/api/project/${projectId}/thread?sort_dir=desc&sort_key=_id`
  return $http.get(url).then(res => res.data.result)
}

function createComment($http, {projectId, data}) {
  const url = `/api/project/${projectId}/comment`
  return $http.post(url, data).then(res => res.data.result)
}

function deleteComment($http, {projectId, commentId}) {
  const url = `/api/project/${projectId}/comment/${commentId}`
  return $http.delete(url).then(res => res.data)
}

function updateThreadStatus($http, {projectId, threadId, status}) {
  const url = `/api/project/${projectId}/thread/${threadId}`
  return $http.put(url, {status}).then(res => res.data.result)
}

function getUserCountry($http) {
  const url = `/api/user-country`
  return $http.get(url).then(res => res.data.result)
}

function getFeatureNews($http) {
  const url = `/api/feature-news`
  return $http(url, {params: {sort_dir: 'desc'}}).then(res => res.data.result.feature_news)
}

function getCouponList($http, {user_id, page}) {
  const url = `/tc-payment/coupon`
  return $http
    .get(url, {
      params: {
        is_admin: 0,
        user_id,
        page,
      },
    })
    .then(res => res.data)
}

function getCouponItem($http, {oid}) {
  const url = `/tc-payment/coupon/${oid}`
  return $http.get(url).then(res => res.data)
}

/**
 * user-actor-meta api
 */
function getUserActorMeta($http) {
  const url = `/api/user-actor-meta`
  return $http.get(url).then(res => res.data)
}

function getBackDownloadList($http) {
  const url = `/api/me/progress-download-list`
  return $http.get(url).then(res => res.data)
}

function deleteBackDownloadList($http, ids) {
  const url = `/api/me/progress-download-list`
  const urlWithQueryString = encodeUrlParams(url, ['ids', ids])
  return $http.delete(urlWithQueryString).then(res => res.data)
}

/**
 *
 * @param {*} $http
 * @param {Object} actorId
 * @param {Object} params
 * @param {Object} method POST, PUT(&DELETE)
 * @returns result = [{uid, actor_id, is_bookmark, memo}]
 */
function updateUserActorMeta($http, {actorId, params, method}) {
  const url = `/api/user-actor-meta/${actorId}`
  return $http[method](url, {
    ...params,
  }).then(res => res.data)
}

/**
 * @param {AxiosInstance} $http
 * @param {Object} param1
 * @param {string} param1.projectId
 */
function getCurrentProjectAiConversationId($http, {projectId}) {
  const url = `/api/project/${projectId}/conversation/current`
  return $http.post(url).then(res => res.data)
}

/**
 * @param {AxiosInstance} $http
 * @param {Object} payload
 * @param {string} payload.conversationId
 */
function getAiConversationMessages($http, {conversationId}) {
  const url = `/api/conversation/${conversationId}/message`
  return $http.get(url).then(res => res.data)
}

/**
 * @param {AxiosInstance} $http
 * @param {Object} payload
 * @param {string} payload.content
 * @param {string} payload.conversationId
 */
function postAiChatMessage($http, {content, editorContent = '', conversationId}) {
  const url = `/api/conversation/${conversationId}/message`
  const payload = {
    content,
    editor_content: editorContent,
  }
  return $http.post(url, payload).then(res => res.data)
}

/**
 * @param {AxiosInstance} $http
 * @param {Object} payload
 * @param {string} payload.conversationId
 * @param {string} payload.messageId
 */
function stopAiMessageGeneration($http, {conversationId, messageId}) {
  const url = `/api/conversation/${conversationId}/message/${messageId}/stop`
  return $http.put(url).then(res => res.data)
}

/**
 * @param {AxiosInstance} $http
 * @param {Object} payload
 * @param {string} payload.conversationId
 * @param {string} payload.messageId
 */
function getAiChatMessage($http, {conversationId, messageId}) {
  const url = `/api/conversation/${conversationId}/message/${messageId}`
  return $http.get(url).then(res => res.data)
}

/**
 * @param {AxiosInstance} $http
 * @param {Object} payload
 * @param {string} payload.projectId
 * @param {'gpt-3.5-turbo' | 'gpt-4'} payload.model
 */
function createNewAiConversation($http, {projectId, model}) {
  const url = `/api/project/${projectId}/conversation`
  return $http.post(url, {model}).then(res => res.data)
}

/**
 * @param {AxiosInstance} $http
 * @param {Object} payload
 * @param {string} payload.srtUploadUrl
 * @param {Blob} payload.srtFileBlob
 * @param {string} [payload.filename]
 */
async function uploadSrtFile($http, {srtUploadUrl, srtFileBlob, filename = ''}) {
  const postUrl = `/api/download/${srtUploadUrl}/srt`
  const res = await $http.post(postUrl, {filename})
  const srtPresignedUrl = res.data.result.srt_upload_url
  await $http.put(srtPresignedUrl, srtFileBlob)
  await $http.put(postUrl)
}

/**
 *
 * @param {AxiosInstance} $http
 * @param {Object} payload {project_uid, project_name, sentence_list, duration, video_list, bgm_list, format, quality}
 * @returns string
 */
function postAudioMix($http, payload) {
  const url = `/api/audio-mix`
  return $http.post(url, payload).then(res => res.data.result.downloading.timestamp)
}

/**
 *
 * @param {AxiosInstance} $http
 * @param {string} projectId
 * @param {string} timestamp
 * @returns {string, string}
 */
function getAudioMix($http, projectId, timestamp) {
  const url = `/api/audio-mix/${projectId}/${timestamp}`
  return $http.get(url).then(res => res.data.result.downloading)
}

function getIpWhitelists($http) {
  const url = `/api/user/ip-access-list`
  return $http.get(url).then(res => res.data.result)
}

function createIpWhitelist($http, ipData) {
  const url = `/api/user/ip-access-list`
  return $http.post(url, ipData).then(res => res.data.result)
}

function updateIpWhitelist($http, oid, ipData) {
  const url = `/api/user/ip-access-list/${oid}`
  return $http.put(url, ipData).then(res => res.data.result)
}
function deleteIpWhitelist($http, oid) {
  const url = `/api/user/ip-access-list/${oid}`
  return $http.delete(url).then(res => res.data.result)
}

function getDataRetentionSetting($http) {
  const url = `/api/user/data-retention`
  return $http.get(url).then(res => res.data.result)
}

function updateDataRetentionSetting($http, period) {
  const url = `/api/user/data-retention`
  return $http.put(url, {period}).then(res => res.data.result)
}
export default backendApi

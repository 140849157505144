import backend from '@/backend/backend-api.js'
import {nanoid} from 'nanoid'
import queryCacheStore from '@/store/modules/queryCache'
import actorStore from '@/store/modules/actor'
import editorStore from '@/store/modules/editor'
import videoEditorStore from '@/store/modules/videoEditor'
import * as Sentry from '@sentry/vue'
import {getReplacedTitle} from '@/libs/utils/download'
import {getDownloadSentences, getAssetLists} from './getAudioMixItems'
import {AUDIO_PLAY_TYPE} from '@/libs/modules/player/audioPlayer'

export default class TypecastEditorDownloader {
  speakIndex = 0
  qIndex = 0
  qStart = false
  downloadWatch = null
  downloadFailureWatch = null
  onProgress = null
  onDownloadComplete = null
  onFailure = null
  progressInterval = null
  cacheDirtyCheckInterval = null

  downloadStart = false

  downloadWay = {
    mergeDown: true,
    fileType: 'mp3',
    quality: 'normal',
    selectDownload: false,
  }

  downloadStoreId = null

  _store = null

  queryCacheStoreKey = ''
  actorStoreKey = ''
  editorStoreKey = ''
  videoEditorStoreKey = ''

  _context = null

  _share = false

  _projectTitle = ''
  _fileName = ''
  _qStride = 16

  completeDownload = false

  cacheCheckInterval = null

  _batchProgress = 0

  constructor({context, store, downloadWay, spectificQuery}) {
    this._store = store
    this._context = context
    this.downloadStoreId = nanoid()
    this.queryCacheStoreKey = `typecast/download/${this.downloadStoreId}/queryCache`
    this.actorStoreKey = `typecast/download/${this.downloadStoreId}/actor`
    this.editorStoreKey = `typecast/download/${this.downloadStoreId}/editor`
    this.videoEditorStoreKey = `typecast/download/${this.downloadStoreId}/videoEditor`
    // this.queryCacheStoreKey = `typecast/queryCache`
    // this.actorStoreKey = `typecast/actor`
    // this.editorStoreKey = `typecast/editor`

    if (downloadWay) {
      this.downloadWay = downloadWay
    }

    this.createTempDownloadStore()
    this.setDownloadData(spectificQuery)
  }

  createTempDownloadStore() {
    this._store.registerModule(['typecast', 'download', this.downloadStoreId], {
      namespaced: true,
      state: () => ({}),
    })

    this._store.registerModule(['typecast', 'download', this.downloadStoreId, 'queryCache'], queryCacheStore)
    this._store.registerModule(['typecast', 'download', this.downloadStoreId, 'actor'], actorStore)
    this._store.registerModule(['typecast', 'download', this.downloadStoreId, 'editor'], editorStore)
    this._store.registerModule(['typecast', 'download', this.downloadStoreId, 'videoEditor'], videoEditorStore)
  }

  setDownloadData(spectificQuery) {
    const projectTitle = this._store.getters['typecast/editor/projectTitle']
    const fileName = this._store.getters['typecast/editor/fileName']
    const creditId = this._store.getters['typecast/editor/creditId']
    const hasSpectificQuery = spectificQuery && Object.keys(spectificQuery).length > 0
    const queries = hasSpectificQuery ? spectificQuery : this._store.getters['typecast/queryCache/queries']

    this._store.commit(`${this.queryCacheStoreKey}/setQueries`, queries)
    this._store.commit(
      `${this.queryCacheStoreKey}/setQueryCacheItems`,
      this._store.getters['typecast/queryCache/queryCacheItems'],
    )
    this._store.commit(`${this.actorStoreKey}/setCandidateActors`, this._store.getters['typecast/actor/candidateActor'])
    this._store.commit(`${this.queryCacheStoreKey}/setDownloadWay`, this.downloadWay)
    this._store.commit(`${this.editorStoreKey}/setProjectTitle`, projectTitle)
    this._store.commit(`${this.editorStoreKey}/setFileName`, fileName)
    this._store.commit(`${this.editorStoreKey}/setCreditId`, creditId)
    this._store.commit(
      `${this.queryCacheStoreKey}/setStyleLabelVersionListRaw`,
      this._store.getters['typecast/queryCache/styleLabelVersionList'],
    )
    this.projectTitle = projectTitle
    this.fileName = fileName
  }

  setDownloadWatch() {
    const SPEAK_STRIDE = 4
    const queries = this._store.getters[`${this.queryCacheStoreKey}/queries`]

    this.downloadFailureWatch = this._store.watch(
      state => {
        return `${this.queryCacheStoreKey}/downloadError`.split('/').reduce((acc, name) => acc[name], state)
      },
      val => {
        if (val) {
          this.onFailure(new Error('Download Fail'))
          this.cancel()
        }
      },
    )
    this.downloadWatch = this._store.watch(
      state => {
        return `${this.queryCacheStoreKey}/downloadBufferItems`.split('/').reduce((acc, name) => acc[name], state)
      },
      val => {
        try {
          if (this.isCompleteDownloadBuffer(val)) {
            if (val.length < Object.keys(queries).length) {
              const speakChunk = val.slice(this.speakIndex, this.speakIndex + SPEAK_STRIDE)
              const remainSpeak = speakChunk.reduce((count, item) => {
                return item.speak && item.audio ? count : count + 1
              }, 0)
              if (remainSpeak === 0) {
                this.speakIndex += SPEAK_STRIDE
                this.buffering()
              }
            } else if (val.length === Object.keys(queries).length) {
              if (this._share) {
                if (this.qStart === false) {
                  this.qStart = true
                  this.shareSpeak()
                }
              } else {
                if (this.qStart === false) {
                  this.qStart = true
                  if (this.downloadWay.quality == 'hd2') {
                    this.qBatchAll()
                  } else {
                    this.qBatch()
                  }
                } else {
                  if (this.downloadWay.quality == 'hd2') {
                    const remainHd2Q = val.reduce((count, item) => {
                      return item.q ? count : count + 1
                    }, 0)
                    if (remainHd2Q === 0) {
                      this.downloadSpeak()
                      return
                    }
                  } else {
                    const chunkQ = val.slice(this.qIndex, this.qIndex + this._qStride)
                    const remainQ = chunkQ.reduce((count, item) => {
                      return item.q ? count : count + 1
                    }, 0)
                    if (remainQ === 0) {
                      const wholeRemainQ = val.reduce((count, item) => {
                        return item.q ? count : count + 1
                      }, 0)
                      if (wholeRemainQ === 0) {
                        this.downloadSpeak()
                        return
                      }
                      this.qIndex += this._qStride
                      this.qBatch()
                    }
                  }
                }
              }
            }
          } else if (val.length && val.some(item => item.speak && !item.speak.audio && !item.audio)) {
            if (this.forceBatch) {
              return
            }
            if (this.cacheCheckInterval) {
              return
            }
            const isNotLoadingBuffer = item => item.speak && !item.speak.audio
            this.cacheCheckInterval = setInterval(() => {
              const dirtyCheckList = val.filter(isNotLoadingBuffer)
              dirtyCheckList.forEach(downloadBufferItem => {
                const queryCacheItems = this._store.getters[`${this.queryCacheStoreKey}/queryCacheItems`]
                const targetQueryCacheItems = queryCacheItems[downloadBufferItem.hash]
                const cacheAudio = targetQueryCacheItems.audio
                const cacheSpeak = targetQueryCacheItems.speak
                if (cacheAudio && cacheSpeak.audio) {
                  this._store.commit(`${this.queryCacheStoreKey}/updateDownloadBufferItem`, {
                    bufferItem: downloadBufferItem,
                    obj: {audio: cacheAudio, speak: cacheSpeak},
                  })
                }
              })
              if (!this._store.getters[`${this.queryCacheStoreKey}/downloadBufferItems`].some(isNotLoadingBuffer)) {
                clearInterval(this.cacheCheckInterval)
                this.cacheCheckInterval = null
              }
            }, 1000)
          }
        } catch (error) {
          console.error(error)
        }
      },
    )
  }

  isCompleteDownloadBuffer(bufferList) {
    // Speak 객체가 없거나, Speak 객체에 status가 done이 아닌 객체가 없는 경우 -> 버퍼에 들어있는 모든 아이템들의 음성이 생성되었을 경우
    return (
      bufferList.length &&
      !bufferList.some(
        item => item.speak === null || (item.speak && item.speak.status !== 'done' && item.speak.status !== undefined),
      )
    )
  }
  downloadAudio({onProgress, onDownloadComplete, onFailure}) {
    const queries = this._store.getters[`${this.queryCacheStoreKey}/queries`]
    this.onProgress = onProgress
    this.onDownloadComplete = onDownloadComplete
    this.onFailure = onFailure

    if (Object.keys(queries).length) {
      this._store.commit(`${this.queryCacheStoreKey}/resetDownloadBuffer`)
    } else {
      this.onFailure(new Error('Query is Null'))
      this.clearDownloadParams()
      return
    }
    this._store.commit(`${this.queryCacheStoreKey}/setDownloadError`, false)
    this.progressInterval = setInterval(() => {
      const percentValue = this.percentValue()
      this.onProgress(percentValue)
    }, 1000)
    this.setDownloadWatch()
    this.buffering()
  }

  shareAudio({onProgress, onShareComplete, onFailure}) {
    this._share = true
    this.onShareComplete = onShareComplete
    this.downloadAudio({
      onProgress,
      onFailure,
      onDownloadComplete: onShareComplete,
    })
  }
  qBatch() {
    this._store.dispatch(`${this.queryCacheStoreKey}/qBatchAndUpdate`, {
      from: this.qIndex,
      to: this.qIndex + this._qStride,
    })
  }

  qBatchAll() {
    this._store.dispatch(`${this.queryCacheStoreKey}/qBatchAndUpdateAll`)
  }

  buffering() {
    this._store.dispatch(`${this.queryCacheStoreKey}/createCache`, {
      index: this.speakIndex,
      candidateActor: this._store.getters[`${this.actorStoreKey}/candidateActor`],
      download: true,
    })
    this._store
      .dispatch(`${this.queryCacheStoreKey}/batchAndUpdate`, {index: this.speakIndex, download: true})
      .catch(error => {
        Sentry.captureException(error)
        this._store.commit(`${this.queryCacheStoreKey}/setDownloadError`, true)
      })
  }

  makeQueryList() {
    return this._store.getters[`${this.queryCacheStoreKey}/downloadBufferItems`].map(item => {
      const query = this._store.getters[`${this.queryCacheStoreKey}/queries`][item.queryId]
      return {
        speak_url: item.speak.speak_url,
        silence_sec: query.silence / 1000,
        pre_silence_sec: query.rest_sec,
      }
    })
  }

  async postAudioMix($http, projectId) {
    const realTotalDuration = this._store.getters[`${this.queryCacheStoreKey}/realTotalDuration`]
    const totalTransitionDuration = this._store.getters[`${this.queryCacheStoreKey}/totalTransitionDuration`]
    const lastAssetEndTime = this._store.getters[`${this.videoEditorStoreKey}/lastAssetEndTime`]

    const timestamp = await backend.postAudioMix($http, {
      project_uid: projectId,
      project_name: this.fileName,
      sentence_list: getDownloadSentences(this.downloadWay.quality),
      duration: Math.max(realTotalDuration + totalTransitionDuration, lastAssetEndTime),
      video_list: await Promise.all(getAssetLists(AUDIO_PLAY_TYPE.VIDEO)),
      bgm_list: await Promise.all(getAssetLists(AUDIO_PLAY_TYPE.BGM)),
      format: this.downloadWay.fileType,
      quality: this.downloadWay.quality,
    })

    const {mixed_audio_url: cloudfrontUrl, download_id: srtUploadUrl} = await this.pollingAudioMix(
      $http,
      projectId,
      timestamp,
    )
    return {cloudfrontUrl, srtUploadUrl}
  }

  async postBatchDownload($http) {
    const {url, download_id: srtUploadUrl} = await backend.speakDownloadBatch($http, {
      multiple: this.downloadWay.mergeDown == 'false',
      format: this.downloadWay.fileType,
      quality: this.downloadWay.quality,
      query_list: this.makeQueryList(),
      credit_id: this._store.getters[`${this.editorStoreKey}/creditId`],
      project_id: this._store.state.typecast.editor.projectId,
      filename: this.fileName,
    })

    const res = await this.pollingBatchDownload($http, url)
    const currentQualityItem = res[this.downloadWay.quality][this.downloadWay.fileType]
    const isMergeDown = this.downloadWay.mergeDown
    const cloudfrontUrl = isMergeDown ? currentQualityItem.download_url : currentQualityItem.download_multiple_url

    return {cloudfrontUrl, srtUploadUrl}
  }

  async downloadSpeak() {
    if (this.downloadStart) {
      return
    }

    this.downloadStart = true
    const bgmList = this._store.state.typecast.videoEditor.timelineAssetLists.bgm

    const $http = this._context.$typecast.$http
    const projectId = this._store.state.typecast.editor.projectId
    const isMixed = bgmList?.length > 0 && this.downloadWay.mergeDown && !this.downloadWay.selectDownload
    try {
      const {cloudfrontUrl, srtUploadUrl} = isMixed
        ? await this.postAudioMix($http, projectId)
        : await this.postBatchDownload($http)
      await this.getFile(cloudfrontUrl + '/cloudfront', srtUploadUrl)
    } catch (error) {
      console.error(error)
      this.onFailure(error)
      this.clearDownloadParams()
      this.clearStoreModule()
      return error
    }
  }

  async pollingBatchDownload($http, url) {
    const res = await $http.get(url).then(res => res.data.result)
    if (res.status === 'done') {
      return res
    } else if (res.status === 'failed') {
      throw new Error('batch download failed')
    }
    await this.sleep()
    return await this.pollingBatchDownload($http, url)
  }

  async pollingAudioMix($http, projectId, timestamp) {
    const res = await backend.getAudioMix($http, projectId, timestamp)
    const {status_type: statusType, timestamp: newTimestamp} = res
    if (statusType === 'COMPLETE') {
      return res
    } else if (statusType === 'FAILED') {
      throw new Error('audio mix failed')
    }
    await this.sleep()
    return await this.pollingAudioMix($http, projectId, newTimestamp)
  }

  sleep() {
    return new Promise(resolve => setTimeout(resolve, 1000))
  }

  async getFile(cloudfrontUrl, srtUploadUrl) {
    try {
      if (this.completeDownload) {
        return
      }

      const $http = this._context.$typecast.$http
      this.completeDownload = true
      const isMergeDown = this.downloadWay.mergeDown
      const filename = `${getReplacedTitle(this.fileName)}.${isMergeDown ? this.downloadWay.fileType : 'zip'}`

      const awsUrl = await $http.post(cloudfrontUrl, {filename}).then(res => res.data.result)
      if (this.onProgress) {
        this.onProgress(100)
      }
      if (this.onDownloadComplete) {
        this.onDownloadComplete({url: awsUrl, cloudfrontUrl, srtUploadUrl})
      }
      this.clearDownloadParams()
      this.clearStoreModule()
    } catch (error) {
      if (this.onFailure) {
        //NOTE: error.response?.data?.message?.error_code 부분에 에러 코드를 확인 할 수 있게 에러 결과값을 수정
        this.onFailure({
          response: {
            data: {
              message: {
                error_code: 'download/url-failed',
              },
            },
          },
        })
      }
      this.clearDownloadParams()
      this.clearStoreModule()
      return error
    }
  }

  clearDownloadParams() {
    this.speakIndex = 0
    this.qIndex = 0
    this.qStart = false
    if (this.progressInterval) {
      clearInterval(this.progressInterval)
    }
    if (this.downloadWatch) {
      this.downloadWatch()
    }
    if (this.downloadFailureWatch) {
      this.downloadFailureWatch()
    }

    this.downloadFailureWatch = null
    this.progressInterval = null
    this.downloadWatch = null
    this.onProgress = null
    this.onDownloadComplete = null
    this.onFailure = null
    this.completeDownload = false
    this.cacheCheckInterval = null
  }

  clearStoreModule() {
    if (!this._store.state.typecast.download[this.downloadStoreId]) {
      return
    }
    // if production, remove download store
    if (process.env.NODE_ENV === 'production') {
      this._store.unregisterModule(['typecast', 'download', this.downloadStoreId, 'queryCache'])
      this._store.unregisterModule(['typecast', 'download', this.downloadStoreId, 'actor'])
      this._store.unregisterModule(['typecast', 'download', this.downloadStoreId, 'editor'])
      this._store.unregisterModule(['typecast', 'download', this.downloadStoreId])
    }
  }

  percentValue() {
    // Q Batch progress percentage
    return Math.round((this.done() / this.total()) * 0.85 * 100)
  }

  total() {
    return Object.keys(this._store.getters[`${this.queryCacheStoreKey}/queries`]).length * 2 + 1
  }

  done() {
    let doneCount = this._store.getters[`${this.queryCacheStoreKey}/downloadBufferItems`].reduce((count, item) => {
      let acc = count
      if (item.speak && item.audio) {
        acc += this.share ? 2 : 1
      }
      if (item.q && this.downloadWay.quality != 'hd2') {
        acc += 1
      }
      return acc
    }, 0)

    if (this.downloadWay.quality == 'hd2') {
      doneCount += Math.floor((this.total() * this._store.getters[`${this.queryCacheStoreKey}/hd2Progress`]) / 200)
    }
    return doneCount
  }

  shareSpeak() {
    const queryList = this.makeQueryList()
    const $http = this._context.$typecast.$http
    const projectTitle = this._store.getters[`${this.editorStoreKey}/projectTitle`]
    backend
      .speakShareBatch($http, projectTitle ? projectTitle : 'untitled', queryList)
      .then(res => {
        this.onProgress(100)
        this.onShareComplete(res.result.share)
        this.clearDownloadParams()
        this.clearStoreModule()
      })
      .catch(error => {
        this.onFailure(error)
      })
  }

  async cancel() {
    try {
      if (!this._share) {
        this._store.dispatch(`${this.queryCacheStoreKey}/cancelAll`)
      }
      this.clearDownloadParams()
      clearInterval(this.cacheDirtyCheckInterval)
      this.cacheDirtyCheckInterval = null
      this.setTimeout(() => {
        this.clearStoreModule()
      }, 10000)
    } catch (error) {
      return error
    }
  }
}
